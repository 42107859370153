import React from "react";

import { Button } from "@unchained/component-library";
import { Formik, Form } from "formik";

import { FormikPatternField } from "Components/FormFields";
import { AppModalManager } from "Shared/components/Modals";
import { yup } from "Utils/yup";

import { VerificationModal } from "./VerificationModal";

const pinSchema = yup.object({
  pin: yup
    .string()
    .required()
    .matches(/^\d{6}$/),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PinValues extends yup.InferType<typeof pinSchema> {}

export const AdminPinForm = () => {
  const handleSubmit = ({ pin }: PinValues) => {
    AppModalManager.open(() => <VerificationModal pin={pin} />);
  };

  return (
    <Formik
      initialValues={{ pin: "" }}
      validationSchema={pinSchema}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({ isValid }) => {
        return (
          <Form className="">
            <span className="text-sm">Request and enter Unchained's support PIN:</span>
            <div className="flex items-center gap-1.5">
              <FormikPatternField
                name="pin"
                hideLabel
                pattern="###-###"
                className="mr-1 w-56"
                showError={false}
              />
              <Button buttonType="submit" disabled={!isValid} className="!px-3">
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
