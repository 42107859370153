import { useContext, useEffect, useState } from "react";

import { RadioButton, RadioGroup } from "@unchained/component-library";

import { MultipleDestination } from "./MultipleDestination";
import { SingleDestination } from "./SingleDestination";
import { TransferDestination } from "./TransferDestination";
import { WithdrawModalContext } from "./context";
import { FlowOption } from "./types";

const getStepOneRadioStateChange = (value: FlowOption) => {
  let state = { isBatchSpendFlow: false, isTransferFlow: false };

  switch (value) {
    case "multiple":
      state.isBatchSpendFlow = true;
      break;

    case "transfer":
      state.isTransferFlow = true;
      break;

    default:
      break;
  }

  return state;
};

const radioOptions: { label: string; value: FlowOption }[] = [
  { label: "Single address", value: "single" },
  { label: "Multiple addresses", value: "multiple" },
  { label: "Transfer (internal)", value: "transfer" },
];

const renderRadioOptions = (exclude?: FlowOption[]) => {
  let filteredOptions = [...radioOptions];
  if (exclude && exclude.length) {
    filteredOptions = radioOptions.filter(({ value }) => exclude.indexOf(value) === -1);
  }
  return filteredOptions.map(({ label, value }) => (
    <RadioButton key={value} label={label} noCard value={value} />
  ));
};

export const FlowChooser = ({
  allowSpendToSelf,
  exclude,
}: {
  allowSpendToSelf?: boolean;
  exclude?: FlowOption[];
}) => {
  const { isBatchSpendFlow, isTransferFlow, setState } = useContext(WithdrawModalContext);
  const [step0State, setStep0State] = useState<FlowOption>("single");

  useEffect(() => {
    setState(getStepOneRadioStateChange(step0State));
  }, [step0State, setState]);

  let input;
  if (isBatchSpendFlow && !isTransferFlow) {
    input = <MultipleDestination />;
  } else if (!isBatchSpendFlow && isTransferFlow) {
    input = <TransferDestination />;
  } else {
    // This can be the default if for some reason both isBatchSpendFlow and
    // isTransferFlow are both true. They shouldn't be, but to ensure it better,
    // the flow switcher should use an enum instead of booleans.
    input = <SingleDestination allowSpendToSelf={allowSpendToSelf} />;
  }

  // It'd be cool if this could take options for which flows can be selected.
  return (
    <>
      <RadioGroup
        className="flex flex-col gap-4"
        name="destination-type"
        value={step0State}
        onChange={e => setStep0State(e.target.value as FlowOption)}
      >
        {renderRadioOptions(exclude)}
      </RadioGroup>

      <br />

      {input}
    </>
  );
};
