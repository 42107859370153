import { useGetAccount } from "Shared/api";
import { AccountPersonalOrg, OrgForList } from "Specs/v1/getAccount/200";
import { CompleteOrg, CompleteOrgStateEnum } from "Specs/v1/getOrg/200";

/**
 * An attempt to consolidate key org-state functionality to avoid repetition and
 * change things more consistently throughout the app.
 **/

/** States that indicate an org is "post-onboarding" */
export const postOnboardingStates = ["live", "pending_payment_renewal"] as CompleteOrgStateEnum[];

/** States that indicate a *personal* org has completed basic info, ie
 * the user has completed the KYC necessary to add other org types.
 */
export const personalOrgBasicInfoCompleteStates = [
  "pending_payment",
  ...postOnboardingStates,
] as CompleteOrgStateEnum[];

export const hasCompletedBasicInfo = (org?: AccountPersonalOrg) =>
  personalOrgBasicInfoCompleteStates.includes(org?.state);

/** Fetches the personal org to determine if the current user has completed their basic info. */
export const useHasCompletedBasicInfo = () => {
  const { data } = useGetAccount();
  return hasCompletedBasicInfo(data?.personalOrg);
};

export const needsToPay = (org: CompleteOrg | OrgForList | AccountPersonalOrg) =>
  ["pending_payment", "pending_payment_renewal"].includes(org.state);

export const hasPaidBefore = (org?: CompleteOrg | OrgForList | AccountPersonalOrg) =>
  ["live", "pending_payment_renewal"].includes(org?.state);

export const preBasicInfoStates = [
  "pending_activation",
  "pending_basic_info",
  "pending_resubmission",
] as CompleteOrgStateEnum[];

export const preLiveStates = [
  "pending_activation",
  "pending_basic_info",
  "pending_resubmission",
  "pending_payment",
  "pending_approval",
] as CompleteOrgStateEnum[];

export const isPreLive = (org?: CompleteOrg | OrgForList | AccountPersonalOrg) =>
  preLiveStates.includes(org?.state);
