import {
  Banner,
  Input,
  Modal,
  ModalContent,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";

import { Link } from "Components/Link";
import { AdminPinForm } from "Components/Support/SupportPins/AdminPinForm";
import { useCurrentUser } from "Redux/selectors/hooks";
import { useRefreshGetSupportPin } from "Shared/api";
import { AppModalManager } from "Shared/components/Modals";
import { hyphenateNumber } from "Utils/strings";

export const SupportPinModal = () => {
  const currentUser = useCurrentUser();
  const { pin, timeLeft } = useRefreshGetSupportPin(currentUser.uuid);

  const value = pin ? hyphenateNumber(pin) : "Loading...";
  return (
    <Modal maxWidth="xs" onDismiss={AppModalManager.close}>
      <ModalHeader>
        <ModalTitle>Support PINs</ModalTitle>
      </ModalHeader>
      <ModalContent className="prose !mt-0">
        <p className="mb-6">
          Support PINs are used for mutual identity verification of Unchained employees and
          customers, over the phone and via video calls. These PINs help combat scams where
          attackers pretend to be Unchained employees.{" "}
          <Link to="https://help.unchained.com/support-pins">Learn more</Link>
        </p>
        <span className="text-sm">Provide your client support PIN:</span>
        <div className="relative w-56">
          <Input
            value={value}
            readOnly
            className="w-full border-b-[2px] border-gray-100 py-2 pl-3"
          />
          <div
            className="absolute bottom-0 left-1 rounded-sm border-b-[3px] border-gray-300 transition-all"
            style={{
              width: `${(timeLeft / 120000) * 100}%`,
            }}
          ></div>
        </div>
        <span className="text-xs text-gray-600">Auto-refreshes every two minutes</span>
        <div className="my-6">
          <AdminPinForm />
        </div>
        <Banner type="warning" title="Beware of phishing attempts" className="mt-6">
          <p>
            Be extra vigilant if you were not the one to initiate contact. <br />
            Reject any excuses that "Support PINs are not working right now."
          </p>
        </Banner>
      </ModalContent>
    </Modal>
  );
};
