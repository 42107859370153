import React, { useContext, useEffect, useState } from "react";

import { TextField } from "@unchained/component-library";

import { WithdrawModalContext } from "Components/VaultsView/Show/WithdrawModal";
import { useMemoizedState, useCurrentOrg } from "Redux/selectors/hooks";
import { validateOutflowAddressOverride } from "Utils/validation";

import { InitialBannerWarning } from "./InitialBannerWarning";

type AddressValidation = { addressError: string; overrideable: boolean };

export const SingleDestination = ({ allowSpendToSelf }: { allowSpendToSelf: boolean }) => {
  const org = useCurrentOrg();
  const isIra = org.account_type === "ira";

  let workingAllowSpendToSelf = allowSpendToSelf;
  if (workingAllowSpendToSelf === undefined) {
    workingAllowSpendToSelf = true;
  }
  const { singleDestinationAddress, setState } = useContext(WithdrawModalContext);
  const [addressValidation, setAddressValidation] = useState<AddressValidation>({
    addressError: "",
    overrideable: false,
  });
  const vault = useMemoizedState("vaults.vaultShow.vault");
  useEffect(() => {
    // Validate input
    if (singleDestinationAddress === "") {
      return;
    }

    const validate = async (address: string, vault, allowSpendToSelf: boolean) => {
      const validation: AddressValidation = await validateOutflowAddressOverride(address, vault);
      setAddressValidation(validation);

      if (
        (!validation.addressError && singleDestinationAddress) ||
        (singleDestinationAddress && validation.overrideable === allowSpendToSelf)
      ) {
        setState({ nextDisabled: false });
      }
    };

    validate(singleDestinationAddress, vault, workingAllowSpendToSelf);
  }, [singleDestinationAddress, setState, vault, workingAllowSpendToSelf]);

  return (
    <>
      <TextField
        className="p-1"
        label="Address"
        placeholder="eg. 2N9VBY6Adk26odEYvekjRfUGNpenQ9BiyEK"
        autoFocus
        value={singleDestinationAddress}
        onChange={e => setState({ singleDestinationAddress: e.target.value })}
        helperText={addressValidation.addressError}
        error={
          addressValidation.addressError &&
          (!workingAllowSpendToSelf || !addressValidation.overrideable)
        }
      />
      <br />
      {isIra && <InitialBannerWarning />}
    </>
  );
};
