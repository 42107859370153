import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";

import { AdminTypeEnum } from "Components/UsdActivity/Deposits/types";
import { isValidDepositAdminType } from "Components/UsdActivity/Deposits/utils";
import { WithdrawalAdminTypeEnum } from "Components/UsdActivity/Withdrawals/types";
import { isValidWithdrawalAdminType } from "Components/UsdActivity/Withdrawals/utils";
import { UsdAPI } from "Shared/api";
import {
  ApproverUpdateDepositRequest,
  CreateDepositRequest,
  CreateWithdrawalRequest,
  GetDepositActivity200,
  GetWithdrawalActivity200,
  ManagerUpdateWithdrawalRequest,
  UpdateDepositRequest,
  UpdateWithdrawalRequest,
  VoidCompletedDepositRequest,
} from "Specs/v1";
import { ConfirmerUpdateWithdrawalRequest } from "Specs/v1/confirmerUpdateWithdrawal/request";
import { GetDepositActivityQueryParams } from "Specs/v1/getDepositActivity/params/query";
import { GetWithdrawalActivityQueryParams } from "Specs/v1/getWithdrawalActivity/params/query";

export const usdKeys = {
  getDeposits: (
    page: string,
    perPage: string,
    sortDirection: string,
    total: string,
    status: string
  ) => ["getDeposits", page, perPage, sortDirection, total, status],
  getDeposit: (depositUuid: string) => ["getDeposit", depositUuid],
  getWithdrawals: (
    page: string,
    perPage: string,
    sortDirection: string,
    total: string,
    status: string
  ) => ["getWithdrawals", page, perPage, sortDirection, total, status],
  getWithdrawal: (withdrawalUuid: string) => ["getWithdrawal", withdrawalUuid],
};

export type CreateUsdEntryRequestErrors = { errors: Record<string, Record<string, string[]>> };

export const useGetDepositsQuery = (
  requestParams: GetDepositActivityQueryParams,
  onSuccess: (data: GetDepositActivity200) => void,
  handleOnError?: (err: AxiosError) => void
) => {
  return useQuery(
    usdKeys.getDeposits(
      requestParams.page,
      requestParams.perPage,
      requestParams.sortDirection,
      requestParams.total,
      requestParams.status
    ),
    () => UsdAPI.GetDeposits(requestParams),
    {
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
      onSuccess: data => {
        onSuccess(data);
      },
    }
  );
};

export const useGetDepositsMutation = (handleOnError?: (err: AxiosError) => void) => {
  return useMutation(
    (requestParams: GetDepositActivityQueryParams) => UsdAPI.GetDeposits(requestParams),
    {
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

type useGetDepositProps = {
  depositUuid?: string;
  handleOnError?: (err: AxiosError) => void;
};
export const useGetDeposit = ({ depositUuid, handleOnError }: useGetDepositProps) => {
  return useQuery(usdKeys.getDeposit(depositUuid), () => UsdAPI.GetDeposit(depositUuid), {
    refetchOnWindowFocus: false,
    enabled: !!depositUuid,
    onError: (err: AxiosError) => {
      if (handleOnError) {
        handleOnError(err);
      }
    },
  });
};

export const useCreateDeposit = (
  deposit: CreateDepositRequest,
  onSuccess: () => void,
  handleOnError?: (err?: CreateUsdEntryRequestErrors["errors"]) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(() => UsdAPI.CreateDeposit(deposit), {
    onError: (err: AxiosError) => {
      if (handleOnError) {
        const responseErrors = err?.response?.data as CreateUsdEntryRequestErrors;
        handleOnError(responseErrors?.errors);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getDeposits");
      onSuccess();
    },
  });
};

export const useUpdateDeposit = ({
  depositUuid,
  depositUpdates,
  adminTypes,
  onSuccess,
  handleOnError,
  completedDepositUpdate,
  voidedDepositUpdate,
  voidingCompletedDeposit,
}: {
  depositUuid: string;
  depositUpdates: UpdateDepositRequest | ApproverUpdateDepositRequest | VoidCompletedDepositRequest;
  adminTypes: AdminTypeEnum[];
  onSuccess: () => void;
  handleOnError?: (err: AxiosError) => void;
  completedDepositUpdate?: boolean;
  voidedDepositUpdate?: boolean;
  voidingCompletedDeposit?: boolean;
}) => {
  if (completedDepositUpdate || voidedDepositUpdate) {
    delete depositUpdates.status;
  }

  let apiFunction = () => UsdAPI.UpdateDeposit(depositUuid, depositUpdates as UpdateDepositRequest);

  if (isValidDepositAdminType([AdminTypeEnum.approver, AdminTypeEnum.manager], adminTypes)) {
    apiFunction = () =>
      UsdAPI.ApproverUpdateDeposit(depositUuid, depositUpdates as ApproverUpdateDepositRequest);
  }
  if (voidingCompletedDeposit) {
    apiFunction = () =>
      UsdAPI.VoidCompletedDeposit(depositUuid, depositUpdates as VoidCompletedDepositRequest);
  }

  const queryClient = useQueryClient();
  return useMutation(apiFunction, {
    onError: (err: AxiosError) => {
      if (handleOnError) {
        handleOnError(err);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getDeposits");
      onSuccess();
    },
  });
};

export const useGetWithdrawalsQuery = (
  requestParams: GetWithdrawalActivityQueryParams,
  onSuccess: (data: GetWithdrawalActivity200) => void,
  handleOnError?: (err: AxiosError) => void
) => {
  return useQuery(
    usdKeys.getWithdrawals(
      requestParams.page,
      requestParams.perPage,
      requestParams.sortDirection,
      requestParams.total,
      requestParams.status
    ),
    () => UsdAPI.GetWithdrawals(requestParams),
    {
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
      onSuccess: data => {
        onSuccess(data);
      },
    }
  );
};

export const useGetWithdrawalsMutation = (handleOnError?: (err: AxiosError) => void) => {
  return useMutation(
    (requestParams: GetWithdrawalActivityQueryParams) => UsdAPI.GetWithdrawals(requestParams),
    {
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

type useGetWithdrawalProps = {
  withdrawalUuid?: string;
  handleOnError?: (err: AxiosError) => void;
};
export const useGetWithdrawal = ({ withdrawalUuid, handleOnError }: useGetWithdrawalProps) => {
  return useQuery(
    usdKeys.getWithdrawal(withdrawalUuid),
    () => UsdAPI.GetWithdrawal(withdrawalUuid),
    {
      refetchOnWindowFocus: false,
      enabled: !!withdrawalUuid,
      onError: (err: AxiosError) => {
        if (handleOnError) {
          handleOnError(err);
        }
      },
    }
  );
};

export const useCreateWithdrawal = (
  withdrawal: CreateWithdrawalRequest,
  onSuccess: () => void,
  handleOnError?: (err?: CreateUsdEntryRequestErrors["errors"]) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(() => UsdAPI.CreateWithdrawal(withdrawal), {
    onError: (err: AxiosError) => {
      if (handleOnError) {
        const responseErrors = err?.response?.data as CreateUsdEntryRequestErrors;
        handleOnError(responseErrors?.errors);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getWithdrawals");
      onSuccess();
    },
  });
};

export const useUpdateWithdrawal = ({
  withdrawalUuid,
  withdrawalUpdates,
  adminTypes,
  onSuccess,
  handleOnError,
  completedWithdrawalUpdate,
  voidedWithdrawalUpdate,
}: {
  withdrawalUuid: string;
  withdrawalUpdates:
    | UpdateWithdrawalRequest
    | ConfirmerUpdateWithdrawalRequest
    | ManagerUpdateWithdrawalRequest;
  adminTypes: WithdrawalAdminTypeEnum[];
  onSuccess: () => void;
  handleOnError?: (err: AxiosError) => void;
  completedWithdrawalUpdate?: boolean;
  voidedWithdrawalUpdate?: boolean;
}) => {
  if (completedWithdrawalUpdate || voidedWithdrawalUpdate) {
    delete withdrawalUpdates.status;
  }

  let apiFunction = () =>
    UsdAPI.UpdateWithdrawal(withdrawalUuid, withdrawalUpdates as UpdateWithdrawalRequest);

  if (isValidWithdrawalAdminType([WithdrawalAdminTypeEnum.confirmer], adminTypes)) {
    apiFunction = () =>
      UsdAPI.ConfirmerUpdateWithdrawal(
        withdrawalUuid,
        withdrawalUpdates as ConfirmerUpdateWithdrawalRequest
      );
  }
  if (isValidWithdrawalAdminType([WithdrawalAdminTypeEnum.manager], adminTypes)) {
    apiFunction = () =>
      UsdAPI.ManagerUpdateWithdrawal(
        withdrawalUuid,
        withdrawalUpdates as ManagerUpdateWithdrawalRequest
      );
  }

  const queryClient = useQueryClient();
  return useMutation(apiFunction, {
    onError: (err: AxiosError) => {
      if (handleOnError) {
        handleOnError(err);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getWithdrawals");
      onSuccess();
    },
  });
};
