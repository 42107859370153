import { AdminTypeEnum } from "./types";

export const isValidDepositAmount = (value: number) => {
  // This is the maximum value that can be stored in the database
  // Not certain of the limits we would want to enforce, so this just
  // prevents the user from submitting a value that will cause a
  // back-end error.
  const maxAbsValue = 10 ** 14;

  return Math.abs(value) < maxAbsValue && value !== 0;
};

export const isValidDepositAdminType = (
  validTypes: AdminTypeEnum[],
  adminTypes: AdminTypeEnum[]
) => {
  return validTypes.some(adminType => adminTypes.includes(adminType));
};

export enum depositStatesEnum {
  created = "created",
  escalated = "escalated",
  pending = "pending",
  approved = "approved",
  canceled = "canceled",
  completed = "completed",
}

export const isValidDepositStateTransition = ({
  currentState,
  newState,
  adminTypes,
}: {
  currentState: depositStatesEnum;
  newState: depositStatesEnum;
  adminTypes: AdminTypeEnum[];
}) => {
  switch (currentState) {
    case depositStatesEnum.created:
      if (isValidDepositAdminType([AdminTypeEnum.approver, AdminTypeEnum.manager], adminTypes)) {
        return [
          depositStatesEnum.created,
          depositStatesEnum.canceled,
          depositStatesEnum.escalated,
          depositStatesEnum.approved,
        ].includes(newState);
      }
      return [
        depositStatesEnum.created,
        depositStatesEnum.canceled,
        depositStatesEnum.escalated,
      ].includes(newState);
    case depositStatesEnum.escalated:
      if (isValidDepositAdminType([AdminTypeEnum.approver, AdminTypeEnum.manager], adminTypes)) {
        return [
          depositStatesEnum.created,
          depositStatesEnum.canceled,
          depositStatesEnum.escalated,
          depositStatesEnum.approved,
        ].includes(newState);
      }
      return [
        depositStatesEnum.created,
        depositStatesEnum.canceled,
        depositStatesEnum.escalated,
      ].includes(newState);
    case depositStatesEnum.completed:
      return [depositStatesEnum.completed, depositStatesEnum.canceled].includes(newState);
    case depositStatesEnum.canceled:
      return [depositStatesEnum.canceled].includes(newState);
    default:
      return false;
  }
};
