import { AlertCircle, FloatingIcon, Trash } from "@unchained/component-library";
import { useMutation, useQueryClient } from "react-query";

import { accountQueryKeys } from "Shared/api";
import { OrgAPI } from "Shared/api/v2";
import { triggerConfirmationModal } from "Shared/components/Modals";
import { CompleteOrg } from "Specs/v1/getAccount/200";
import { isPreLive } from "Utils/orgState";
import { useEasyToasts } from "Utils/toasts";

export const DeleteOrgIcon = ({ org }: { org: CompleteOrg }) => {
  const queryClient = useQueryClient();
  const { showErrorToast } = useEasyToasts();

  const closeAccount = useMutation(() => OrgAPI.CloseAccount(org.uuid), {
    onSuccess: () => queryClient.invalidateQueries(accountQueryKeys.get),
    onError: error => showErrorToast(error),
  });

  const preLive = isPreLive(org);

  if (!(preLive && org.allowed_actions.includes("delete"))) return null;

  return (
    // This has group hover properties to notice when the whole AvatarItem is hovered
    <div className="flex h-full items-center justify-center pr-4 opacity-0 transition-opacity group-hover:opacity-100">
      <Trash
        className="h-4 w-4 cursor-pointer fill-transparent text-primary-900 transition-colors hover:fill-gray-300"
        data-testid="delete-org-icon"
        onClick={e => {
          e.stopPropagation();

          triggerConfirmationModal({
            title: null,
            text: (
              <div className="flex flex-col items-center gap-6">
                <FloatingIcon size="md" color="red" Icon={AlertCircle} shadow={false} />
                <div className="text-center">
                  <p>
                    Are you sure you want to delete this account? Please note this action cannot be
                    undone.
                  </p>
                </div>
              </div>
            ),
            confirmationText: "Yes, delete",
            onConfirm: closeAccount.mutate,
          });
        }}
      />
    </div>
  );
};
