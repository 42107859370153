import { useCurrentOrg } from "Redux/selectors/hooks";

import { BatchDropzone } from "./BatchDropzone";
import { InitialBannerWarning } from "./InitialBannerWarning";
import { genCsvTemplateContents } from "./functions";

export const MultipleDestination = () => {
  const org = useCurrentOrg();
  const isIra = org.account_type === "ira";

  return (
    <>
      <div className="flex-grow pb-3 text-sm text-gray-600">
        Upload a .csv file containing your destination addresses.
        <br />
        <a
          download="sample.csv"
          href={`data:application/octet-stream;charset=utf-8;base64,${genCsvTemplateContents()}`}
        >
          Use this template
        </a>{" "}
        to complete your transaction.
      </div>

      <BatchDropzone />

      <br />

      {isIra && <InitialBannerWarning />}
    </>
  );
};
