import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";

import {
  resetKeyCheckWizardAction,
  setBIP32PathKeyCheckAction,
  setKeyUuidKeyCheckAction,
  setSuccessMessageKeyCheckAction,
  setWalletCurrencyKeyCheckAction,
  setWalletTypeKeyCheckAction,
} from "Actions/keyActions/keyCheckWizardActions";
import { getAccountSpendingDataAction } from "Actions/transactionActions/spendingActions";
import { SigningHardwareWalletSelector } from "Components/Shared/wizard/HardwareWalletSelector";
import { Wizard } from "Components/Shared/wizard/Wizard";
import {
  spendingOperationSelector,
  spendingSigRequestsSelector,
} from "Redux/selectors/spendingSelectors";
import { splitOperationType } from "Utils/operationTypes";

import { SignKeyStep } from "./SignKeyStep";

class SigningWizardBase extends Component {
  static propTypes = {
    accountType: PropTypes.string.isRequired,
    accountUUID: PropTypes.string,
    currentSliceMember: PropTypes.object,
    operationUUID: PropTypes.string,
    getAccountSpendingData: PropTypes.func.isRequired,
    resetWizard: PropTypes.func.isRequired,
    setKeyCheckBIP32Path: PropTypes.func.isRequired,
    setKeyCheckSuccessMessage: PropTypes.func.isRequired,
    setKeyCheckCurrency: PropTypes.func.isRequired,
    setKeyCheckUuid: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
    };
    this.manifest = [
      {
        component: SigningHardwareWalletSelector, // 0
      },
      {
        render: (
          wizardProps // 1
        ) => (
          <SignKeyStep onSignSuccess={this.handleSignSuccess} back={wizardProps.back} {...props} />
        ),
      },
    ];
    this.state = {
      startIndex: 0,
    };
  }

  componentDidMount() {
    this.setKeyCheckData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedSigRequest.uuid !== this.props.selectedSigRequest.uuid) {
      this.setState({ startIndex: 0 });
      this.props.resetKeyTypeSelection();
    }
  }

  onNextComponent() {
    const startIndex = this.state.startIndex + 1;
    this.setState({ startIndex });
  }

  setKeyCheckData = () => {
    const {
      selectedSigRequest,
      setKeyCheckBIP32Path,
      setKeyCheckUuid,
      setKeyCheckCurrency,
      setKeyCheckSuccessMessage,
    } = this.props;
    setKeyCheckBIP32Path(selectedSigRequest.account_key.bip32_path);
    setKeyCheckUuid(selectedSigRequest.account_key.uuid);
    setKeyCheckCurrency("BTC");
    setKeyCheckSuccessMessage(`Completed device check for ${selectedSigRequest.account_key.name}.`);
  };

  handleSignSuccess = async () => {
    const { getAccountSpendingData, accountUUID, operationType, operationUUID } = this.props;

    const [accountType, operation] = splitOperationType(operationType);

    await getAccountSpendingData(accountType, accountUUID, operation, operationUUID);
  };

  componentWillUnmount() {
    this.props.resetWizard();
  }

  render() {
    return (
      <div>
        <Wizard
          manifest={this.manifest}
          onNextComponent={() => this.onNextComponent()}
          startIndex={this.state.startIndex}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  setKeyCheckBIP32Path: setBIP32PathKeyCheckAction,
  setKeyCheckSuccessMessage: setSuccessMessageKeyCheckAction,
  setKeyCheckCurrency: setWalletCurrencyKeyCheckAction,
  setKeyCheckUuid: setKeyUuidKeyCheckAction,
  resetWizard: resetKeyCheckWizardAction,
  resetKeyTypeSelection: () => setWalletTypeKeyCheckAction(""),
  getAccountSpendingData: getAccountSpendingDataAction,
};

const mapStateToProps = state => {
  return {
    ...spendingOperationSelector(state),
    ...spendingSigRequestsSelector(state),
  };
};

export const SigningWizard = connect(mapStateToProps, mapDispatchToProps)(SigningWizardBase);
