import { ListNotifications200 } from "Specs/v2";

import { ApiType } from "../apiType";

export const NotificationsAPIV2Factory = (API: ApiType) => ({
  GetV2Notifications: async (orgUuid?: string): Promise<ListNotifications200> => {
    return (
      await API.Get<ListNotifications200>(`/notifications${orgUuid ? `?org_uuid=${orgUuid}` : ""}`)
    ).data;
  },

  PutV2NotificationState: async (
    uuid: string,
    state: ListNotifications200["notifications"][0]["state"]
  ) => {
    const resp = await API.Put<null>(`/notifications/${uuid}/state`, `${state}`, {
      "Content-Type": "application/json",
    });
    return resp;
  },
});
