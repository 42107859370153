import { Loader } from "@unchained/component-library";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

import { BasicPage } from "Components/Pages";
import { currentOrgSelector } from "Redux/selectors";
import { useGetOrg } from "Shared/api/hooks";
import {
  CompleteOrg,
  CompleteOrgAccountTypeEnum,
  COMPLETE_ORG_ACCOUNT_TYPE,
} from "Specs/v1/getOrg/200";
import { useErrorToast } from "Utils/toasts";

import { OrgDelete } from "./OrgDelete";
import { OrgManageName } from "./OrgManageName";
import OrgMembersAndQuorumSettings from "./OrgMembersAndQuorumSettings";

const { BUSINESS, TRUST, IRA, INDIVIDUAL } = COMPLETE_ORG_ACCOUNT_TYPE;

export const manageOrgTitleMap = {
  [TRUST]: "Manage trust",
  [BUSINESS]: "Manage business",
  [IRA]: "Manage IRA",
  [INDIVIDUAL]: "Manage account",
} as Record<CompleteOrgAccountTypeEnum, string>;

const OrgManage = () => {
  const { uuid, name } = useSelector(currentOrgSelector);
  const orgQuery = useGetOrg(uuid);
  const showErrorToast = useErrorToast();

  if (orgQuery.isLoading) return <Loader className="m-auto" />;

  if (orgQuery.isError) {
    showErrorToast(orgQuery.error as Error);
    return <Navigate to="/home" />;
  }

  const org = orgQuery.data as CompleteOrg;
  const { personal, max_members, allowed_actions, account_type } = org;
  const canEditName = ![TRUST, IRA].includes(account_type) && allowed_actions.includes("update");

  if (personal && max_members === 1) return <Navigate to={"/home"} />;

  return (
    <BasicPage className="max-w-5xl gap-4" title={manageOrgTitleMap[account_type]}>
      {[TRUST, BUSINESS].includes(account_type) ? <OrgMembersAndQuorumSettings /> : null}

      <div className="rounded-lg border border-gray-200 bg-white p-4 md:p-8">
        <div className="flex flex-col items-start justify-start gap-4">
          <h1>Account settings</h1>
          <div>
            <OrgManageName uuid={uuid} name={name} canEdit={canEditName} />
          </div>
          {allowed_actions.includes("delete") && <OrgDelete />}
        </div>
      </div>
    </BasicPage>
  );
};

export default OrgManage;
