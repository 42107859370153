import { KeyIcon } from "Components/Shared/Icons/KeyIcon";

const KeyCheckNotification = ({
  uuid,
  account_key_name,
}: {
  uuid: string;
  account_key_name: string;
}) => {
  return {
    to: `/account_keys/${uuid}`,
    title: `Device check for ${account_key_name} is due`,
    Icon: KeyIcon,
  };
};

export { KeyCheckNotification };
