import { useContext, useState } from "react";

import { Banner, LeadingCheckbox } from "@unchained/component-library";

import { Link } from "Components/Link";

import { WithdrawModalContext } from "./context";

export const TakeYourTimeWarning = () => {
  const { setState } = useContext(WithdrawModalContext);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = checked => {
    setIsChecked(checked);
    setState({ nextDisabled: !checked });
  };

  return (
    <>
      <Banner title="Take your time when moving bitcoin" type="warning">
        Scammers create urgency to get you to move bitcoin. If someone else is guiding your actions,
        evaluate the circumstances before proceeding.
        <br />
        <br />
        Attackers have been known to attempt impersonation of our support staff. Always use{" "}
        <Link target="_blank" to="/support" className="mt-4 text-primary-600">
          Support PINs
        </Link>{" "}
        when receiving support.
        <br />
        <br />
        When in doubt, hang up and call our official phone support line at{" "}
        <strong>+1 (844) 486-2424</strong>.
      </Banner>

      <LeadingCheckbox
        containerClassName="mt-8"
        name="Acknowledge warning"
        onChange={() => handleCheckboxChange(!isChecked)}
        checked={isChecked}
        label={
          <p>
            I have read the warnings above and am ready to proceed with creating a bitcoin
            transaction.
          </p>
        }
      />
    </>
  );
};
