import React from "react";

import { ButtonProps, Tooltip } from "@unchained/component-library";
import cn from "classnames";

import { Link } from "Components/Link";
import { useNavigationDispatch, useNavigationState } from "Contexts/Navigation";

export const NavLink = React.forwardRef<
  HTMLAnchorElement,
  Omit<ButtonProps, "disabled"> & {
    child?: boolean;
    // An active class will automatically be added if the link to value matches the URL
    // If you want to apply it in some other context, set this to true.
    active?: boolean;
    closeDrawerOnClick?: boolean;
    to?: string;
    disableWithOptionalNote?: boolean | string;
    linkClassName?: string;
  }
>(function NavLinkNoRef(
  {
    to,
    startIcon,
    endIcon,
    children,
    className,
    child = false,
    onClick,
    active,
    disableWithOptionalNote,
    closeDrawerOnClick = true,
    linkClassName = "",
  },
  ref
) {
  const { drawerOpen } = useNavigationState();
  const { setDrawerOpen } = useNavigationDispatch();
  const activeClassName = "bg-primary-800 rounded-md";
  const component = (
    <Link
      className={cn(
        "flex min-w-full items-center px-3 text-md text-white transition-colors hover:text-white hover:no-underline",
        child ? "py-2 font-reg" : "py-3 font-semi",
        {
          "text-primary-300 hover:text-primary-300": child,
          [activeClassName]: active,
          "cursor-not-allowed": disableWithOptionalNote,
        },
        className
      )}
      to={disableWithOptionalNote ? "" : to}
      activeClassName={activeClassName}
      onClick={() => {
        if (disableWithOptionalNote) return;
        if (onClick) onClick();

        if (drawerOpen && closeDrawerOnClick) {
          setDrawerOpen(false);
        }
      }}
      ref={ref}
    >
      <div className="mr-3 w-6">{startIcon}</div>
      <div className={cn(linkClassName)}>{children}</div>
      {endIcon ? <div className="ml-auto flex items-center">{endIcon}</div> : null}
    </Link>
  );

  return typeof disableWithOptionalNote === "string" ? (
    <Tooltip content={disableWithOptionalNote}>{component}</Tooltip>
  ) : (
    component
  );
});
