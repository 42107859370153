import { useState } from "react";

import {
  Banner,
  Loader,
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "@unchained/component-library";
import { useMutation, useQueryClient } from "react-query";

import { Link, useNavigate } from "Components/Link";
import { accountQueryKeys, useGetAccount } from "Shared/api";
import { OrgAPI } from "Shared/api/v2";
import { useClosureAllowed } from "Shared/api/v2/hooks/orgs";
import { AppModalManager } from "Shared/components/Modals";
import { CompleteOrg } from "Specs/v1/getAccount/200";
import { CheckIfOrgClosureIsAllowed200 } from "Specs/v2";
import { isPreLive } from "Utils/orgState";
import { useEasyToasts } from "Utils/toasts";

type NotAllowedReason = CheckIfOrgClosureIsAllowed200["notAllowedReason"];

const LoadingModal = () => (
  <Modal onDismiss={AppModalManager.close}>
    <Loader className="h-full w-full" />
  </Modal>
);

const orgName = (org: CompleteOrg) => (org.account_type === "individual" ? "Individual" : org.name);

/**
 * "Internal" component, exported for testing
 * Displayed in case where closure is allowed.
 */
const _ClosureAllowedModal = ({ org, isLast }: { org: CompleteOrg; isLast: boolean }) => {
  const [isClosed, setIsClosed] = useState(false);
  const { showErrorToast } = useEasyToasts();
  const queryClient = useQueryClient();
  const preLive = isPreLive(org);
  const navigate = useNavigate();

  const closeAccount = useMutation(() => OrgAPI.CloseAccount(org.uuid), {
    onSuccess: () => {
      queryClient.invalidateQueries(accountQueryKeys.get);
      setIsClosed(true);
      if (isLast) navigate("/onboard");
    },
    onError: error => {
      showErrorToast(error);
      AppModalManager.close();
    },
  });

  const name = orgName(org);

  if (isClosed) {
    return (
      <Modal onDismiss={AppModalManager.close}>
        <ModalHeader>
          <ModalTitle>Your account "{name}" has been successfully closed</ModalTitle>
        </ModalHeader>
        <ModalContent>
          <p>
            No further action is needed. If this action was done by mistake, please reach out to our
            team.
          </p>
        </ModalContent>
        <ModalFooter actions={[{ children: "OK", onClick: AppModalManager.close }]} />
      </Modal>
    );
  }

  const permanent = org.account_type !== "individual";

  const saveInfoRecommendation = (
    <>
      <p>
        Before you close your account, we recommend that you save your financial information by
        downloading:
      </p>
      <ul>
        <li>Account statements</li>
        <li>Trade statements</li>
        <li>Wallet configuration files</li>
      </ul>
    </>
  );

  const bannerMessage = permanent
    ? "This action can't be undone. Once you close your account, you will lose all of your transaction/wallet records and won't be able to reopen this same account in the future."
    : "You can resume payment to restore your account.";

  return (
    <Modal onDismiss={AppModalManager.close}>
      <ModalHeader>
        <ModalTitle>
          Are you sure you want to {permanent ? "permanently " : ""}close your "{name}" account?
        </ModalTitle>
      </ModalHeader>
      <ModalContent>
        {!preLive && (
          <Banner type="warning" title={permanent ? "Warning" : "Note"} className="mb-4">
            {bannerMessage}
          </Banner>
        )}
        <div className="prose">
          {preLive
            ? "This action will delete your onboarding progress, and you will have to start over."
            : saveInfoRecommendation}
        </div>
      </ModalContent>
      <ModalFooter
        actions={[
          {
            children: permanent ? "Permanently close account" : "Close account",
            onClick: () => closeAccount.mutate(),
          },
          { children: "Keep account", onClick: AppModalManager.close },
        ]}
      />
    </Modal>
  );
};

/**
 * "Internal" component, exported for testing
 * Displayed in case where closure is not allowed.
 */
const _ClosureNotAllowedModal = ({
  name,
  closureDeets,
}: {
  name: string;
  closureDeets: CheckIfOrgClosureIsAllowed200;
}) => {
  const { notAllowedReason, notAllowedDetails } = closureDeets;

  let title = `We can't close the account "${name}"`;
  const titleReasons = {
    funded_vaults: `it has one or more funded vaults`,
    active_loans: `it has one or more active loans`,
    funded_vaults_and_active_loans: `it has one or more active vaults and loans`,
    transactions_in_progress: "it has transactions in progress",
    not_account_owner: "you don't have ownership permissions",
    org_type_conflict: "this org type cannot be closed",
    account_keys_in_use: "some of its keys are not deleteable",
    personal_org_state_conflict: "it is a personal org in an uncloseable state",
  } as Record<NotAllowedReason, string>;
  const titleReason = titleReasons[notAllowedReason];

  if (titleReason) title = `${title} because ${titleReason}`;

  const { fundedVaults, activeLoans, transactionsInProgress } = notAllowedDetails || {};

  return (
    <Modal onDismiss={AppModalManager.close}>
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      <ModalContent className="prose" style={{ marginTop: "0.5rem" }}>
        {!!fundedVaults?.length ? (
          <div>
            <p>
              Please withdraw all bitcoin balances from the following vaults in this account before
              moving forward:
            </p>
            <ul>
              {fundedVaults.map(vault => (
                <li key={vault.uuid}>
                  <strong>{vault.name}</strong>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {!!activeLoans?.length ? (
          <div>
            <p>Please close the following active loans before moving forward:</p>
            <ul>
              {activeLoans.map(loan => (
                <li key={loan.uuid}>
                  <strong>{loan.name || loan.uuid}</strong>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {!!transactionsInProgress?.length ? (
          <div>
            <p>
              You'll be able to close your account once the following transactions are complete:
            </p>
            <ul>
              {transactionsInProgress.map(tx => (
                <li key={tx.txid}>
                  <strong>{tx.txid}</strong>
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        {notAllowedReason === "not_account_owner" && (
          <p>
            If you think that you should have ownership permissions, please contact your account's
            administrator or reach out to our support team.
          </p>
        )}
        {(
          [
            "personal_org_state_conflict",
            "org_type_conflict",
            "account_keys_in_use",
          ] as NotAllowedReason[]
        ).includes(notAllowedReason) && (
          <p>
            If you think this is an error, please contact your account's administrator or reach out
            to our support team.
          </p>
        )}
        <p>
          <Link to="https://help.unchained.com/how-do-i-delete-my-account">
            Click here for more information on how to close your account.
          </Link>
        </p>
      </ModalContent>
      <ModalFooter actions={[{ children: "OK", onClick: AppModalManager.close }]} />
    </Modal>
  );
};

/**
 * "Internal" component, exported for testing
 * The main modal component, having received all API props
 **/
export const _CloseAccountModal = ({
  org,
  closureDeets,
  isLast,
}: {
  org: CompleteOrg;
  closureDeets: CheckIfOrgClosureIsAllowed200;
  isLast: boolean;
}) => {
  const name = orgName(org);
  const { isAllowed } = closureDeets;

  if (isAllowed) return <_ClosureAllowedModal org={org} isLast={isLast} />;

  return <_ClosureNotAllowedModal name={name} closureDeets={closureDeets} />;
};

/** Fetches closure details for org, and renders appropriate modal based on those details. */
export const CloseAccountModal = ({ org }: { org: CompleteOrg }) => {
  const { showErrorToast } = useEasyToasts();
  const closureAllowedQuery = useClosureAllowed(org.uuid);
  const { data: { personalOrg, memberships = [] } = {} } = useGetAccount();
  const isLast = [personalOrg, ...memberships.map(m => m.org)].filter(Boolean).length === 1;

  if (closureAllowedQuery.isLoading) return <LoadingModal />;

  if (closureAllowedQuery.isError) {
    showErrorToast(closureAllowedQuery.error as object);
    AppModalManager.close();
  }

  const closureDeets = closureAllowedQuery.data;

  return <_CloseAccountModal org={org} closureDeets={closureDeets} isLast={isLast} />;
};
