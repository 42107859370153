import { GetIraAgreement200, PatchFortisInfoRequest } from "Specs/v2";

import { ApiType } from "../apiType";

export const IRAPlansAPIV2Factory = (API: ApiType) => ({
  GetAgreement: async (uuid: string) =>
    (await API.Get<GetIraAgreement200>(`/orgs/${uuid}/ira-plan/agreement`)).data,

  ConsentToAgreement: async (uuid: string) =>
    (await API.Post(`/orgs/${uuid}/ira-plan/agreement`)).data,

  DeclineAgreement: async (uuid: string) =>
    (await API.Delete(`/orgs/${uuid}/ira-plan/agreement`)).data,

  PatchFortisInfo: async (orgUuid: string, data: PatchFortisInfoRequest) =>
    (await API.Patch(`/orgs/${orgUuid}/ira-plan/fortis-info`, data)).data,
});
