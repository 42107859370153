import React from "react";

import { useCurrentUser } from "Redux/selectors/hooks";
import { useRefreshGetSupportPin } from "Shared/api";
import { AppModalManager } from "Shared/components/Modals";
import { useIsActivePage } from "Utils/hooks/useIsActivePage";
import { hyphenateNumber } from "Utils/strings";

import { SupportPinModal } from "./SupportPinModal";

export const SupportPinButton = () => {
  const currentUser = useCurrentUser();
  const isUnchainedAdmin = currentUser.accepted_org_types.includes("unchained");
  const isPageActive = useIsActivePage("/support");
  const { pin } = useRefreshGetSupportPin(currentUser.uuid, !isUnchainedAdmin);
  const value = pin ? hyphenateNumber(pin) : "Loading...";
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    AppModalManager.open(SupportPinModal);
  };

  if (isUnchainedAdmin) return null;

  const hoverStyles = isPageActive ? "hover:bg-primary-600" : "hover:bg-primary-700";

  return (
    <button
      className={`group/button z-10 float-right rounded-md px-2 py-1 text-xs ${hoverStyles}`}
      onClick={handleClick}
    >
      <span className="text-xs">PIN:</span>{" "}
      <span className="text-sm text-yellow-300 underline group-hover/button:text-white">
        {value}
      </span>
    </button>
  );
};
