import React, { useCallback, useEffect, useMemo } from "react";

import { LoadingModal } from ".";
import { CheckCircleOutline, CloudDownload } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { ButtonProps, ModalContent, ModalFooter } from "@unchained/component-library";
import cn from "classnames";
import { capitalize } from "lodash";
import { useQueryClient } from "react-query";

import { CashDepositSlideout } from "Components/PaymentMethods/CashDepositSlideout";
import {
  setModalStatus,
  TRADE_CONFIRMATION_ACCEPTED,
  TRADE_CONFIRMATION_TIMEOUT_MODAL_STATUS,
  useBuyBitcoinDispatch,
  useBuyBitcoinStore,
} from "Contexts/BuyBitcoin";
import { AppModalManager } from "Shared/components/Modals";
import { AppSlideoutManager } from "Shared/components/SlideoutManager";
import { formatCurrency } from "Utils/strings";

import { PreviewContentItem, TradingFeesTooltip } from "../components";
import {
  useAmountPaidFromCashBalance,
  useDownloadTradeStatement,
  useIsPaidViaCashBalanceOnly,
} from "../hooks";
import styles from "./OrderSummaryModal.module.scss";
import purchaseStyles from "./PreviewOrderModal.module.scss";
import { TradeModalContainer } from "./TradeModalContainer";

interface OrderSummaryModalProps {
  next: () => void;
  setShowModalCloseButton: (boolean) => void;
}

/**
 * Component that displays a summary of the user's bitcoin purchase if a trade has been accepted by all participants.
 * @param {function} next - Function to move on to the next modal in a series of buybitcoin modals.
 * @param {Function} setShowModalCloseButton - Function to set Boolean value to show or hide the modal close button.
 * @returns {React.Component}
 */
const OrderSummaryModal = ({ setShowModalCloseButton }: OrderSummaryModalProps) => {
  const openDepositFundsSlideout = () => {
    AppSlideoutManager.open(() => <CashDepositSlideout />);
  };

  const {
    bitcoinPrice,
    totalCost,
    tradeConfirmationStatus,
    feeAmountUSD,
    destination,
    purchaseAmountUSD,
    purchaseAmountBTC,
    fees,
    wireAmountDue,
    remainingCash,
    confirmedBuyTradeIds,
    tradeStatementsDocumentDownloadingStatuses,
  } = useBuyBitcoinStore();

  // Show modal close button
  useEffect(() => {
    setShowModalCloseButton(true);
  }, [setShowModalCloseButton]);

  const dispatch = useBuyBitcoinDispatch();
  const queryClient = useQueryClient();

  useEffect(() => {
    // slightly delay the invalidation of the cash balance query to ensure the trade has been processed.
    setTimeout(() => {
      queryClient.invalidateQueries("getCashBalance");
    }, 1000);
  }, [queryClient]);

  const feeCostFormatted = feeAmountUSD ? `$${formatCurrency(feeAmountUSD)}` : "---";

  const isPaidViaCashBalanceOnly = useIsPaidViaCashBalanceOnly(wireAmountDue);

  const amountPaidFromCashBalance = useAmountPaidFromCashBalance(
    totalCost,
    wireAmountDue,
    isPaidViaCashBalanceOnly
  );

  /**
   * If the trade has not been confirmed after 6 seconds
   * and we are still on the loading screen we can assume
   * the trade request has failed. Therefore we can set the modal status
   * to represent such failure, navigating us to an ErrorModal.
   */
  const tradeConfirmationTimeout = useCallback(() => {
    return setTimeout(() => {
      dispatch(setModalStatus(TRADE_CONFIRMATION_TIMEOUT_MODAL_STATUS));
    }, 6000);
  }, [dispatch]);

  const title = (
    <div className={styles.confirmContainer}>
      <h1 className={styles.title}>Order confirmed</h1>
      <CheckCircleOutline classes={{ root: styles.checkIcon }} fontSize="inherit" color="primary" />
    </div>
  );
  const downloadStatement = useDownloadTradeStatement();

  const createFooterButtons = (): ButtonProps[] => {
    const downloadButton = {
      type: isPaidViaCashBalanceOnly ? ("primary" as const) : ("secondary" as const),
      onClick: () => downloadStatement(confirmedBuyTradeIds[0]),
      children: tradeStatementsDocumentDownloadingStatuses[confirmedBuyTradeIds[0]] ? (
        <CircularProgress data-testid="button-loading-spinner" color="inherit" size={20} />
      ) : (
        <>
          <CloudDownload classes={{ root: styles.cloudIcon }} />
          Download statement
        </>
      ),
    };

    const depositFundsButton = {
      type: "primary" as const,
      className: purchaseStyles.button,
      onClick: openDepositFundsSlideout,
      children: "Wire instructions",
    };

    return isPaidViaCashBalanceOnly ? [downloadButton] : [depositFundsButton, downloadButton];
  };

  return tradeConfirmationStatus === TRADE_CONFIRMATION_ACCEPTED ? (
    <TradeModalContainer title={title} onBack={AppModalManager.close}>
      <h2
        className={cn(purchaseStyles.btcHeader, purchaseStyles.btcHeader__primary)}
      >{`${purchaseAmountBTC} BTC`}</h2>
      <p className={purchaseStyles.destination}>
        To: {capitalize(destination.type)} {destination.id}
      </p>
      <p className={styles.successfulText}>
        Your trade was successfully executed. Bitcoin will typically be deposited to your vault
        within two business days.
      </p>
      <ModalContent>
        <div
          className={cn(purchaseStyles.contentContainer, purchaseStyles.contentContainer__primary)}
        >
          <PreviewContentItem title="BTC price" content={`$${formatCurrency(bitcoinPrice)}`} />
          <PreviewContentItem
            title={`Amount (USD)`}
            content={`$${formatCurrency(purchaseAmountUSD)}`}
          />
          <PreviewContentItem
            title={[
              "Unchained fee",
              <TradingFeesTooltip
                infoIconClass={styles.infoIcon}
                key="tradingFeesToolTip"
                fees={fees}
              />,
            ]}
            content={feeCostFormatted}
          />
          <PreviewContentItem
            title={"Total cost"}
            content={`$${formatCurrency(totalCost)}`}
            contentStyles={purchaseStyles.totalCost}
            titleStyles={purchaseStyles.totalCost}
          />
        </div>
        <div
          className={cn(purchaseStyles.contentContainer, purchaseStyles.contentContainer__primary)}
        >
          <>
            <PreviewContentItem
              title={"Payment from cash"}
              content={`$${formatCurrency(amountPaidFromCashBalance)}`}
            />

            <PreviewContentItem
              title={"Remaining cash"}
              content={`$${formatCurrency(remainingCash)}`}
            />
          </>

          {!isPaidViaCashBalanceOnly && (
            <PreviewContentItem
              title={"Wire amount due"}
              content={`$${formatCurrency(wireAmountDue)}`}
              contentStyles={purchaseStyles.totalCost}
              titleStyles={purchaseStyles.totalCost}
            />
          )}
        </div>
      </ModalContent>
      <ModalFooter actions={createFooterButtons()}></ModalFooter>
    </TradeModalContainer>
  ) : (
    <LoadingModal timeoutHandler={tradeConfirmationTimeout} />
  );
};

export { OrderSummaryModal };
