import React, { useEffect, useState, ReactNode } from "react";

import { Cancel, CheckCircle } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { Modal, ModalFooter, ModalHeader, ModalTitle } from "@unchained/component-library";

import { SupportPinsAPI } from "Shared/api";
import { AppModalManager } from "Shared/components/Modals";

interface VerificationModalBaseProps {
  title: string;
  buttonText?: string;
  icon?: ReactNode;
  children: ReactNode | ReactNode[];
}

const VerificationModalBase = ({
  title,
  buttonText,
  icon,
  children,
}: VerificationModalBaseProps) => {
  return (
    <Modal onDismiss={AppModalManager.close} maxWidth="xs">
      <ModalHeader>
        <ModalTitle>{title}</ModalTitle>
        <div className="mt-6">
          {children}
          {icon ? <div className="my-6 text-center">{icon}</div> : null}
        </div>
        {buttonText ? (
          <ModalFooter
            style={{ marginTop: 0 }}
            actions={[{ children: buttonText, onClick: AppModalManager.close }]}
          />
        ) : null}
      </ModalHeader>
    </Modal>
  );
};

const SuccessModal = () => {
  return (
    <VerificationModalBase
      title="PIN confirmed"
      buttonText="Close"
      icon={<CheckCircle className="text-d-xl text-green-500" />}
    >
      You are now safe to proceed speaking to your Unchained representative.
    </VerificationModalBase>
  );
};

const ErrorModal = () => {
  return (
    <VerificationModalBase
      title="PIN verification failed"
      buttonText="Close"
      icon={<Cancel className="text-d-xl text-red-500" />}
    >
      Unchained employees will always be able to provide a valid PIN. If you are seeing this
      message, hang up and call our phone support directly at <strong>+1 (844) 486-2424.</strong>
    </VerificationModalBase>
  );
};

const LoadingModal = () => {
  return (
    <VerificationModalBase title="Verifying PIN">
      <CircularProgress />
    </VerificationModalBase>
  );
};

interface VerificationModalProps {
  pin: string;
}

export const VerificationModal = ({ pin }: VerificationModalProps) => {
  const [loading, setLoading] = useState(true);
  const [valid, setValid] = useState(false);

  useEffect(() => {
    SupportPinsAPI.VerifyAdminSupportPin({ pin })
      .then(() => {
        setValid(true);
        setLoading(false);
      })
      .catch(() => {
        setValid(false);
        setLoading(false);
      });
  }, [setLoading, setValid, pin]);

  if (loading) {
    return <LoadingModal />;
  }

  return valid ? <SuccessModal /> : <ErrorModal />;
};
