import React, { useContext, useEffect, useRef, createContext, ReactNode, useCallback } from "react";

import {
  fetchBuyBitcoinData,
  doesUserHavePendingWireAmountDue,
} from "Components/TradingDesk/hooks";
import { makeStore } from "Contexts/makeStore";
import { TradingAPI } from "Shared/api";

import {
  setWebSocketStatus,
  setStreamingQuoteStatus,
  clearModalStateRetainVaultAndAmount,
  setStreamingQuoteStatusFromStreamingQuoteResponse,
} from "./buyBitcoinActions";
import {
  SET_AMOUNT,
  SET_AMOUNT_CURRENCY,
  SET_VAULT,
  CLEAR_MODAL_STATE,
  CLEAR_MODAL_STATE_RETAIN_DESTINATION_AND_AMOUNT,
  SET_BUY_BITCOIN_INFO,
  SET_IS_GET_BUY_BITCOIN_INFO_LOADING,
  SET_IS_GET_BUY_BITCOIN_INFO_ERROR,
  SET_IS_TRADING_FEATURE_AVAILABLE,
  SET_STREAMING_QUOTE_RESPONSE,
  SET_STREAMING_QUOTE_RESPONSE_ISLOADING,
  SET_BUY_BITCOIN_SUCCESS,
  SET_WEB_SOCKET_STATUS,
  SET_MODAL_STATUS,
  OFFLINE_MODAL_STATUS,
  WEB_SOCKET_NULL,
  TRADE_CONFIRMATION_NULL,
  TRADE_CONFIRMATION_ACCEPTED,
  TRADE_CONFIRMATION_REJECTED,
  SET_BUY_BITCOIN_REJECTED,
  CLEAR_TRADING_STATE,
  SET_TRADE_CONFIRMATION_STATUS,
  STREAMING_QUOTE_CLOSED,
  STREAMING_QUOTE_NULL,
  STREAMING_QUOTE_CLOSE_REQUEST,
  SET_STREAMING_QUOTE_STATUS,
  SET_TRADING_JWT,
  TRADING_MODAL_CLOSED_STATUS,
  USD,
  SET_TRADE_STATEMENTS,
  SET_IS_TRADE_STATEMENT_DOWNLOADING,
  CLOSE_ONBOARDING_TRADING_CARD,
  TRADING_ONBOARDING_REQUIREMENT_STATUS_NOT_DONE,
  SET_OUTSTANDING_TRADE_IDS,
  ONLINE_STATUS,
  BTC,
  AWAITING_NEW_STREAMING_QUOTE,
} from "./buyBitcoinConstants";
import { BuyBitcoinInitialState, BuyBitcoinActions, Currency } from "./types";

const reducer = (
  state: BuyBitcoinInitialState,
  action: BuyBitcoinActions
): BuyBitcoinInitialState => {
  switch (action.type) {
    case SET_AMOUNT:
      const { amount } = action.payload;
      return { ...state, amount };
    case SET_VAULT:
      const { destination } = action.payload;
      return { ...state, destination };
    case SET_AMOUNT_CURRENCY: {
      const { amountCurrency } = action.payload;
      return { ...state, amountCurrency };
    }
    case CLEAR_MODAL_STATE: {
      return {
        ...state,
        ...initialState,
        isTradingFeatureAvailable: state.isTradingFeatureAvailable,
        tradeStatements: state.tradeStatements,
        tradeStatementsDocumentDownloadingStatuses:
          state.tradeStatementsDocumentDownloadingStatuses,
        isTradingOnboardingCardShowing: state.isTradingOnboardingCardShowing,
        onboardingStatus: state.onboardingStatus,
        credit: state.credit,
        confirmedBuyTradeIds: state.confirmedBuyTradeIds,
      };
    }
    case CLEAR_MODAL_STATE_RETAIN_DESTINATION_AND_AMOUNT: {
      return {
        ...state,
        ...initialState,
        isTradingFeatureAvailable: state.isTradingFeatureAvailable,
        destination: state.destination,
        amount: state.amount,
        amountCurrency: state.amountCurrency,
        tradeStatements: state.tradeStatements,
        tradeStatementsDocumentDownloadingStatuses:
          state.tradeStatementsDocumentDownloadingStatuses,
        isTradingOnboardingCardShowing: state.isTradingOnboardingCardShowing,
        onboardingStatus: state.onboardingStatus,
        credit: state.credit,
      };
    }
    case CLEAR_TRADING_STATE: {
      const tradingValues = {
        purchaseAmountBTC: initialState.purchaseAmountBTC,
        purchaseAmountUSD: initialState.purchaseAmountUSD,
        totalCost: initialState.totalCost,
        amountCurrency: initialState.amountCurrency,
        isValidAmount: initialState.isValidAmount,
        destinations: initialState.destinations,
        bitcoinPrice: initialState.bitcoinPrice,
        fees: initialState.fees,
        maximumPurchaseAmount: initialState.maximumPurchaseAmount,
        minimumPurchaseAmount: initialState.minimumPurchaseAmount,
        streamingQuoteID: initialState.streamingQuoteID,
        streamingQuoteVersion: initialState.streamingQuoteVersion,
        confirmedBuyTimeStamp: initialState.confirmedBuyTimeStamp,
        confirmedBuyTradeIds: initialState.confirmedBuyTradeIds,
        remainingCash: initialState.remainingCash,
        wireAmountDue: initialState.wireAmountDue,
      };
      return { ...state, ...tradingValues };
    }
    case SET_BUY_BITCOIN_INFO: {
      const {
        status,
        destinations,
        cash,
        credit,
        minimumPurchaseAmount,
        maximumPurchaseAmount,
        bitcoinPrice,
        fees,
        offlineStatus: { title, description, buttonText, tradeIds, tradeTimestamp },
      } = action.payload;

      // if user only has one vault, set it as the destination
      const destination =
        destinations.vaults.length === 1 ? destinations.vaults[0] : state.destination;
      return {
        ...state,
        status,
        destinations,
        cash,
        credit,
        minimumPurchaseAmount,
        maximumPurchaseAmount,
        bitcoinPrice,
        fees,
        destination: { id: destination.id, type: "vault" },
        offlineTitle: title,
        offlineDescription: description,
        offlineButtonText: buttonText,
        webSocketStatus: initialState.webSocketStatus,
        confirmedBuyTradeIds: tradeIds,
        confirmedBuyTimeStamp: tradeTimestamp,
      };
    }
    case SET_IS_GET_BUY_BITCOIN_INFO_LOADING: {
      const { isLoading } = action.payload;
      return { ...state, isGetBuyBitcoinInfoLoading: isLoading };
    }
    case SET_IS_GET_BUY_BITCOIN_INFO_ERROR: {
      const { isError } = action.payload;

      const newBuyBitcoinStatus = isError ? OFFLINE_MODAL_STATUS : state.status;

      return { ...state, isGetBuyBitcoinInfoError: isError, status: newBuyBitcoinStatus };
    }
    case SET_IS_TRADING_FEATURE_AVAILABLE: {
      const {
        onboardingStatus,
        isBuyBitcoinEnabled,
        isCardShowing,
        wireAmountDue: { amountUsd, session },
      } = action.payload;

      let updatedCredit = { ...state.credit, usedUsd: amountUsd };

      return {
        ...state,
        onboardingStatus,
        isTradingFeatureAvailable: isBuyBitcoinEnabled,
        isTradingOnboardingCardShowing: isCardShowing,
        credit: updatedCredit,
        showWireDueToast: {
          isShow: doesUserHavePendingWireAmountDue(amountUsd),
          session: session,
        },
      };
    }
    case CLOSE_ONBOARDING_TRADING_CARD: {
      return {
        ...state,
        isTradingOnboardingCardShowing: false,
      };
    }
    case SET_TRADING_JWT: {
      const { jwt } = action.payload;
      return { ...state, tradingJwt: jwt };
    }
    case SET_STREAMING_QUOTE_RESPONSE: {
      const {
        BTCPrice,
        purchaseAmountBTC,
        purchaseAmountUSD,
        streamingQuoteVersion,
        streamingQuoteId,
        feeAmountUSD,
        remainingCash,
        wireAmountDue,
        totalAmountUSD,
      } = action.payload;

      const streamingQuoteStatus = setStreamingQuoteStatusFromStreamingQuoteResponse(
        state.streamingQuoteStatus,
        "OPEN"
      );

      // check that the streaming quote amount is equal to the amount that the user entered
      const isStreamingUsdAmountSameAsEnteredAmount =
        state.amountCurrency === USD && totalAmountUSD === state.amount;
      const isStreamingBtcAmountSameAsEnteredAmount =
        state.amountCurrency === BTC && purchaseAmountBTC === state.amount;

      if (isStreamingUsdAmountSameAsEnteredAmount || isStreamingBtcAmountSameAsEnteredAmount) {
        return {
          ...state,
          purchaseAmountBTC: purchaseAmountBTC,
          purchaseAmountUSD: purchaseAmountUSD,
          bitcoinPrice: BTCPrice,
          totalCost: totalAmountUSD,
          feeAmountUSD: feeAmountUSD,
          streamingQuoteID: streamingQuoteId,
          streamingQuoteVersion: streamingQuoteVersion,
          streamingQuoteStatus: streamingQuoteStatus,
          remainingCash,
          wireAmountDue,
        };
      } else {
        // If the streaming quote we received does not match the
        // the amount that the user entered do not use the streaming quote.
        // This is unlikely to occur but it may occur if the user changes
        // the amount and or currency just as a new streaming quote is being received.
        return {
          ...state,
          streamingQuoteStatus: AWAITING_NEW_STREAMING_QUOTE,
        };
      }
    }
    case SET_STREAMING_QUOTE_RESPONSE_ISLOADING: {
      const { isLoading } = action.payload;
      return { ...state, isStreamingQuoteResponseLoading: isLoading };
    }
    case SET_BUY_BITCOIN_SUCCESS: {
      const {
        purchaseAmountBTC,
        BTCPrice,
        timestamp,
        tradeId,
        totalAmountUSD,
        wireAmountDue,
        remainingCash,
      } = action.payload;

      let totalCost;
      let updatedCredit = state.credit;
      try {
        updatedCredit = { ...state.credit, usedUsd: totalAmountUSD };
      } catch {
        totalCost = NaN;
      }

      return {
        ...state,
        purchaseAmountBTC: purchaseAmountBTC,
        bitcoinPrice: BTCPrice,
        totalCost: totalAmountUSD,
        credit: updatedCredit,
        tradeConfirmationStatus: TRADE_CONFIRMATION_ACCEPTED,
        streamingQuoteStatus: STREAMING_QUOTE_CLOSED,
        confirmedBuyTimeStamp: timestamp,
        confirmedBuyTradeIds: [tradeId],
        remainingCash,
        wireAmountDue,
      };
    }
    case SET_BUY_BITCOIN_REJECTED: {
      return {
        ...state,
        tradeConfirmationStatus: TRADE_CONFIRMATION_REJECTED,
        streamingQuoteStatus: STREAMING_QUOTE_CLOSED,
      };
    }
    case SET_TRADE_CONFIRMATION_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        tradeConfirmationStatus: status,
      };
    }
    case SET_WEB_SOCKET_STATUS: {
      const { webSocketStatus } = action.payload;
      return {
        ...state,
        webSocketStatus,
      };
    }

    case SET_MODAL_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        status,
      };
    }
    case SET_STREAMING_QUOTE_STATUS: {
      const { streamingQuoteStatus } = action.payload;
      return {
        ...state,
        streamingQuoteStatus: streamingQuoteStatus,
      };
    }

    case SET_TRADE_STATEMENTS: {
      const { tradeStatements } = action.payload;
      return {
        ...state,
        tradeStatements: tradeStatements,
      };
    }
    case SET_IS_TRADE_STATEMENT_DOWNLOADING: {
      const { isLoading, tradeId } = action.payload;
      const updatedTradeStatementsDocumentDownloadingStatuses = {
        ...state.tradeStatementsDocumentDownloadingStatuses,
        [tradeId]: isLoading,
      };
      return {
        ...state,
        tradeStatementsDocumentDownloadingStatuses:
          updatedTradeStatementsDocumentDownloadingStatuses,
      };
    }

    case SET_OUTSTANDING_TRADE_IDS: {
      const { outstandingTradeIds } = action.payload;

      return {
        ...state,
        confirmedBuyTradeIds: outstandingTradeIds,
      };
    }

    default: {
      return state;
    }
  }
};

export const initialState: BuyBitcoinInitialState = {
  isTradingFeatureAvailable: false,
  status: TRADING_MODAL_CLOSED_STATUS,
  webSocketStatus: WEB_SOCKET_NULL,
  tradeConfirmationStatus: TRADE_CONFIRMATION_NULL,
  streamingQuoteStatus: STREAMING_QUOTE_NULL,
  amount: "",
  purchaseAmountBTC: "",
  purchaseAmountUSD: "",
  remainingCash: "",
  wireAmountDue: "",
  totalCost: "",
  amountCurrency: USD,
  isValidAmount: false,
  destinations: {
    vaults: [],
    loans: [],
  },
  destination: { id: null, type: "vault" },
  bitcoinPrice: null,
  fees: [],
  feeAmountUSD: null,
  maximumPurchaseAmount: { USD: "", BTC: "" },
  minimumPurchaseAmount: { USD: "", BTC: "" },
  isGetBuyBitcoinInfoLoading: false,
  isGetBuyBitcoinInfoError: false,
  isStreamingQuoteResponseLoading: false,
  offlineTitle: "",
  offlineDescription: "",
  offlineButtonText: "",
  streamingQuoteID: "",
  streamingQuoteVersion: null,
  tradingJwt: "",
  confirmedBuyTimeStamp: "",
  confirmedBuyTradeIds: [],
  tradeStatements: [],
  tradeStatementsDocumentDownloadingStatuses: {},
  isTradingOnboardingCardShowing: false,
  onboardingStatus: {
    hasVault: false,
    isTierThreeProfile: TRADING_ONBOARDING_REQUIREMENT_STATUS_NOT_DONE,
    isBankAccountOnFile: TRADING_ONBOARDING_REQUIREMENT_STATUS_NOT_DONE,
  },
  cash: { availableBalanceUsd: "", cashBalanceUsd: "" },
  credit: { availableUsd: "", usedUsd: "", maxUsd: "", isEnabled: false },
  showWireDueToast: { isShow: false, session: "" },
};

const [BuyBitcoinProvider, useBuyBitcoinStore, useBuyBitcoinDispatch] = makeStore(
  reducer,
  initialState
);

const SocketContext = createContext(null);

interface BuyBitcoinWebSocketProviderProps {
  children: ReactNode;
}

const BuyBitcoinWebSocketProvider = ({ children }: BuyBitcoinWebSocketProviderProps) => {
  const dispatch = useBuyBitcoinDispatch();
  const { tradingJwt } = useBuyBitcoinStore();
  const ws = useRef(null);

  useEffect(() => {
    ws.current = TradingAPI.OpenTradeWebSocket(tradingJwt);

    // set onopen, onclose, onerror, and onmessage methods
    TradingAPI.SetTradeWebSocketLifeCycleMethods(ws.current, dispatch);

    // The Socker provider is unmounting therefore the
    // web socket should close and status should be set to its initial state of WEB_SOCKET_NULL
    return () => {
      if (ws.current.close) {
        ws.current.close();
      }

      dispatch(setWebSocketStatus(WEB_SOCKET_NULL));
    };
  }, [dispatch, tradingJwt]);

  return <SocketContext.Provider value={ws.current}>{children}</SocketContext.Provider>;
};

const useSocket = () => {
  const socket = useContext(SocketContext);
  const { streamingQuoteID, streamingQuoteVersion, destination } = useBuyBitcoinStore();

  const dispatch = useBuyBitcoinDispatch();

  const send = useCallback(
    payload => {
      socket.send(JSON.stringify(payload));
    },
    [socket]
  );

  const getStreamingQuote = React.useCallback(
    (amount: string, currency: Currency) => {
      const purchaseAmount = amount;
      const payload = { amount: purchaseAmount, currency: currency };
      send(payload);
    },
    [send]
  );

  const acceptTradeRequest = React.useCallback(() => {
    send({
      confirmed: true,
      version: streamingQuoteVersion,
      id: streamingQuoteID,
      destinationId: destination.id,
      destinationType: destination.type,
    });
  }, [streamingQuoteVersion, streamingQuoteID, send, destination.id, destination.type]);

  const stopStreamingQuote = React.useCallback(
    (orgUuid: string) => {
      send({
        messageType: STREAMING_QUOTE_CLOSE_REQUEST,
        version: streamingQuoteVersion,
        id: streamingQuoteID,
      });

      dispatch(setStreamingQuoteStatus(STREAMING_QUOTE_CLOSED));
      dispatch(clearModalStateRetainVaultAndAmount());
      fetchBuyBitcoinData(dispatch, orgUuid);
    },
    [streamingQuoteVersion, streamingQuoteID, send, dispatch]
  );

  return { socket, getStreamingQuote, acceptTradeRequest, stopStreamingQuote };
};

export {
  BuyBitcoinProvider,
  useBuyBitcoinStore,
  useBuyBitcoinDispatch,
  useSocket,
  BuyBitcoinWebSocketProvider,
};
