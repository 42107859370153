import { useLocation } from "react-router-dom";

export const useIsActivePage = (path: string, matchActiveExactly = true) => {
  const location = useLocation();

  let isActive = false;

  isActive = matchActiveExactly
    ? [location.pathname, location.search].filter(s => s).join("") === path
    : location.pathname.split("?")[0] === path.split("?")[0];

  return isActive;
};
