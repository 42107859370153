export const SET_AMOUNT = "SET_AMOUNT";
export const SET_VAULT = "SET_VAULT";
export const SET_AMOUNT_CURRENCY = "SET_AMOUNT_CURRENCY";
export const CLEAR_MODAL_STATE = "CLEAR_MODAL_STATE";
export const CLEAR_MODAL_STATE_RETAIN_DESTINATION_AND_AMOUNT =
  "CLEAR_MODAL_STATE_RETAIN_DESTINATION_AND_AMOUNT";
export const SET_TRADING_JWT = "SET_TRADING_JWT";
export const SET_BUY_BITCOIN_INFO = "SET_BUY_BITCOIN_INFO";
export const SET_IS_GET_BUY_BITCOIN_INFO_LOADING = "SET_IS_GET_BUY_BITCOIN_INFO_LOADING";
export const SET_IS_GET_BUY_BITCOIN_INFO_ERROR = "SET_IS_GET_BUY_BITCOIN_INFO_ERROR";
export const SET_IS_TRADING_FEATURE_AVAILABLE = "SET_IS_TRADING_FEATURE_AVAILABLE";
export const SET_STREAMING_QUOTE_RESPONSE = "SET_STREAMING_QUOTE_RESPONSE";
export const SET_STREAMING_QUOTE_RESPONSE_ISLOADING = "SET_STREAMING_QUOTE_RESPONSE_ISLOADING";
export const SET_BUY_BITCOIN_SUCCESS = "SET_BUY_BITCOIN_SUCCESS";
export const SET_WEB_SOCKET_STATUS = "SET_WEB_SOCKET_STATUS";
export const SET_MODAL_STATUS = "SET_MODAL_STATUS";
export const SET_BUY_BITCOIN_REJECTED = "SET_BUY_BITCOIN_REJECTED";
export const CLEAR_TRADING_STATE = "CLEAR_TRADING_STATE";
export const SET_TRADE_CONFIRMATION_STATUS = "SET_TRADE_CONFIRMATION_STATUS";
export const SET_STREAMING_QUOTE_STATUS = "SET_STREAMING_QUOTE_STATUS";
export const SET_TRADE_STATEMENTS = "SET_TRADE_STATEMENTS";
export const SET_IS_TRADE_STATEMENT_DOWNLOADING = "SET_IS_TRADE_STATEMENT_DOWNLOADING";
export const CLOSE_ONBOARDING_TRADING_CARD = "CLOSE_ONBOARDING_TRADING_CARD";
export const SET_OUTSTANDING_TRADE_IDS = "SET_OUTSTANDING_TRADE_IDS";

// Modal statuses
export const OFFLINE_MODAL_STATUS = "OFFLINE_MODAL_STATUS";
export const WEB_SOCKET_FAILURE_MODAL_STATUS = "WEB_SOCKET_FAILURE_MODAL_STATUS";
export const TRADE_CONFIRMATION_REJECTED_MODAL_STATUS = "TRADE_CONFIRMATION_REJECTED_MODAL_STATUS";
export const TRADE_CONFIRMATION_TIMEOUT_MODAL_STATUS = "TRADE_CONFIRMATION_TIMEOUT_MODAL_STATUS";
export const TRADING_MODAL_CLOSED_STATUS = "TRADING_MODAL_CLOSED_STATUS";
// Web socket statuses
export const WEB_SOCKET_OPEN = "WEB_SOCKET_OPEN";
export const WEB_SOCKET_CLOSED = "WEB_SOCKET_CLOSED";
export const WEB_SOCKET_NULL = "WEB_SOCKET_NULL"; // web socket has not been open or closed.
export const WEB_SOCKET_ERROR = "WEB_SOCKET_ERROR";

// Streaming quote statuses
export const STREAMING_QUOTE_NULL = "STREAMING_QUOTE_NULL";
export const STREAMING_QUOTE_OPEN = "STREAMING_QUOTE_OPEN";
export const STREAMING_QUOTE_CLOSED = "STREAMING_QUOTE_CLOSED";
export const AWAITING_NEW_STREAMING_QUOTE = "AWAITING_NEW_STREAMING_QUOTE";

// Trade confirmation statuses
export const TRADE_CONFIRMATION_ACCEPTED = "accepted";
export const TRADE_CONFIRMATION_REJECTED = "REJECTED";
export const TRADE_CONFIRMATION_NULL = "TRADE_CONFIRMATION_NULL";

// Trade web socket message types
export const STREAMING_QUOTE_RESPONSE = "STREAMING_QUOTE_RESPONSE";
export const TRADE_RESPONSE = "TRADE_RESPONSE";
export const STREAMING_QUOTE_CLOSE_REQUEST = "STREAMING_QUOTE_CLOSE_REQUEST";

//currency
export const BTC = "BTC";
export const USD = "USD";

// vault status
export const ONLINE_STATUS = "online";
export const OFFLINE_STATUS = "offline";
export const NO_VAULTS_STATUS = "noVaults";
export const AWAITING_SETTLEMENT_STATUS = "awaitingSettlement";
export const INSUFFICIENT_FUNDS_STATUS = "insufficientFunds";

// Onboarding requirement  statuses
export const TRADING_ONBOARDING_REQUIREMENT_STATUS_DONE = "DONE";
export const TRADING_ONBOARDING_REQUIREMENT_STATUS_PENDING = "PENDING";
export const TRADING_ONBOARDING_REQUIREMENT_STATUS_NOT_DONE = "NOT_DONE";
